import { usePage } from '@inertiajs/vue3';
import Cart from '../Models/Cart';

export function useCart() {
    const cart = usePage().props.shop.cart;

    if (!cart) {
        return null;
    }

    return new Cart(cart);
}
